import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, params) {
      // fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .post('/people!getEntranceComment.sv', null, params)

        // .get(`/apps/entrance/dates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
